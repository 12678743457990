import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import './Couriers.scss';

export const Couriers = ({ images }) => {
	const { pic_futar_1, pic_futar_2 } = images;
	return (
		<section id="couriers" className="text-center pt-0">
			<div id="details" className="container">
				<div className="card">
					<div className="card-body">
						<div className="row py-sm-5">
							<div className="col-12">
								<h2>Dolgozz a szabadidődben, vagy akár főállásban!</h2>
							</div>
							<div className="col-sm-6 d-none d-sm-block">
								<Img
									fixed={pic_futar_1.childImageSharp.fixed}
									alt="Ádám"
									className="mt-5 mb-3"
								/>
								<h3 className="my-2">Ádám</h3>
								<h6 className="mb-4">Főállású VIDDL futár</h6>
								<p>Napi 8 óra</p>
								<p>Naponta kb. 80 cím</p>
								<p>Heti 5 nap meló</p>
								<Link
									aria-label="Kb. 80.000 Ft / hét + jatt"
									to="/courier"
									className="btn btn-outline-primary btn-lg font-weight-bold mt-4"
								>
									Kb. 80.000 Ft / hét + jatt
								</Link>
							</div>
							<div className="col-12 col-sm-6">
								<Img
									fixed={pic_futar_2.childImageSharp.fixed}
									alt="Balázs"
									className="mt-5 mb-3"
								/>
								<h3 className="my-2">Balázs</h3>
								<h6 className="mb-4">Diák, részmunkaidőben VIDDL futár</h6>
								<p>Napi 3 óra</p>
								<p>Naponta kb. 27 cím</p>
								<p>Heti pár alkalom</p>
								<Link
									aria-label="Kb. 20.000 Ft / hét + jatt"
									to="/courier"
									className="btn btn-outline-primary btn-lg font-weight-bold mt-4"
								>
									Kb. 20.000 Ft / hét + jatt
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
